import { Row, Col, InputGroup, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import React, { useState, useEffect, useContext, Suspense } from "react";
import { navigate } from '@reach/router';
import { GPContext } from "../context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { CDN } from '../constants'
import { replaceSpecialChars } from '../viewHelperFunctions';


let Terms

const Email = (props) => {
    const { t, i18n } = props;
    const { state, dispatch } = useContext(GPContext);
    let voucherOffer 
    let voucherCoupon
    let voucherPromo
    let cashOffer
    let cashCoupon
    let cashPromo
    var partner = state.partner ? state.partner.toLowerCase() : '';
    var brand = props.brand ? props.brand.toLowerCase() : '';
    var model = props.model ? props.model.toLowerCase() : '';
    var capacity = (props.capacity && props.capacity !== 'stock') ? props.capacity.toLowerCase() : '';
    var powerson = props.powerson ? props.powerson.toLowerCase() : '';
    var condition = props.condition ? props.condition.toLowerCase() : '';
    var contact = props.contact ? props.contact.toLowerCase() : '';
    var offers, cashOffers, voucherOffers

    const promoDevices = ['Apple iPhone 15 Pro', 'Apple iPhone 15 Pro Max', 'Apple iPhone 15 Plus', 'Apple iPhone 15', 'Apple iPhone 14 Pro', 'Apple iPhone 14 Pro Max', 'Apple iPhone 14 Plus', 'Apple iPhone 14', 'Apple iPhone 13 Pro', 'Apple iPhone 13 Pro Max', 'Apple iPhone 13', 'Apple iPhone 13 mini', 'Apple iPhone 12 Pro Max', 'Apple iPhone 12 Pro', 'Apple iPhone 12', 'Apple iPhone 12 mini', 'Apple iPhone 11 Pro Max', 'Apple iPhone 11 Pro', 'Apple iPhone 11','Apple iPhone SE (2022)', 'Apple iPhone SE (2020)']

    var devices = state.devices.filter((a) => a.brand.toLowerCase() === brand.toLowerCase() && a.model.toLowerCase() === model.replaceAll('-', ' ').replaceAll('_', '/').toLowerCase());
    var variants = devices.reduce((map, obj) => {
        map[obj.capacity] = obj;
        return map;
    }, {});
    
    useEffect(() => {
        if (partner === 'pandas') {
            axios.get(`https://imei-service.greenpanda.io/v2/offers/webquote`, 
                    { params : {brand: brand, model: model.replaceAll('-', ' ').replaceAll('_', '/'), condition: condition, capacity: capacity, flow: 'pandas', smartphone:1}}).then(res => {
                offers = res.data.offers;
                cashOffers = offers ? offers.filter((offer) => offer.type === 'cash') : [];
                cashOffers.sort((a, b) => (a.price > b.price) ? -1 : 1)
                voucherOffers = offers ? offers.filter((offer) => offer.type === 'voucher') : [];
                voucherOffers.sort((a, b) => (a.price > b.price) ? -1 : 1)
                dispatch({
                    type: "SET_OFFERS",
                    payload1: cashOffers,
                    payload2: voucherOffers
                });
                voucherOffer = voucherOffers[0].price;
                voucherCoupon = voucherOffers[0].coupon;
                voucherPromo = voucherOffers[0].promo;
                cashOffer = cashOffers[0].price;
                cashCoupon = cashOffers[0].coupon;
                cashPromo = cashOffers[0].promo;
                finalPrice = cashOffer > voucherOffer ? cashOffer + cashPromo + cashCoupon : voucherOffer + voucherCoupon + voucherPromo;
                sessionStorage.setItem('cashOffers', JSON.stringify(cashOffers))
                sessionStorage.setItem('voucherOffers', JSON.stringify(voucherOffers))
            }).catch((error) => {
                console.log(error)
                navigate('/')
            })
        }
    }, [])

    function changeToEng(c) {
        if (c === 'very_good') return 'Like New';
        if (c === 'good') return 'Good';
        if (c === 'poor') return 'Acceptable';
        if (c === 'faulty') return 'Faulty';
    }
    function changeToGreek(c) {
        if (c === 'very_good') return 'Σαν Καινούρια';
        if (c === 'good') return 'Καλή';
        if (c === 'poor') return 'Μέτρια';
        if (c === 'faulty') return 'Κακή';
    }
    
    capacity = capacity.toUpperCase()
    var device = variants[capacity.toUpperCase()];
    let finalPrice = powerson === "ber" ? 1 : device[condition + '_price'];
    if (capacity === '') capacity = 'stock';
    var imageUrl;
    if (brand === 'apple') {
        imageUrl = `https://server.greenpanda.io/images/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.jpg`;
    }else{
        imageUrl = `${CDN}/devices/${device.brand.toLowerCase()}/${device.model.replaceAll(' ', '_').toLowerCase()}.png`
    }

    if(partner.includes("pmm") && promoDevices.includes(device.brand + " " + device.model)){
        finalPrice = finalPrice + 50;
    }

    var offer = {
        brand: device.brand,
        model: device.model,
        name: device.model.length < 5 ? device.brand + " " + device.model : device.model,
        capacity: device.capacity,
        powerson: powerson,
        condition_gr: changeToGreek(condition),
        condition: changeToEng(condition),
        finalPrice: finalPrice,
        istormPrice: finalPrice,
        device_image_url: imageUrl,
        fbclid: localStorage.getItem('fbclid'),
        ttclid: localStorage.getItem('ttclid'),
        gclid: localStorage.getItem('gclid'),
        lang: i18n.language
    }

    const [email, setEmail] = useState("");
    const [submit, setSubmit] = useState(props.success ? props.success : false);

    useEffect(() => {
        window.dataLayer.push({ event: 'Step Email Form' });
    }, [])

    window.brand = brand;
    window.partner = partner;
    window.model = model;
    window.language = i18n.language;
    window.capacity = capacity.toUpperCase();
    window.condition = condition;
    window.step = 'email form';


    useEffect(() => {
        var e = localStorage.getItem("email");
        if (e) {
            setEmail(e);
            document.getElementsByClassName("email")[0].value = e;
        }
    }, [])

    useEffect(() => {
        Terms = React.lazy(() => import('../terms/terms_' + i18n.language));
    }, [i18n.language])

    function sendOffer() {
        localStorage.setItem("email", email);
        offer.email = email;

        dispatch({ type: "contact", payload: { email: email } })
        setSubmit(true);

        const headers2 =
            { 'Content-Type': 'application/json' };

        var category;
        if (state.category === 'ipad') {
            category = 'tablet'
        } else {
            category = 'smartphone'
        }

        axios.post(`https://communications.greenpanda.io/webquote/${partner}`, {
            email: email,
            brand: device.brand,
            model: device.model,
            price: finalPrice,
            cashPrice: state.cashOffers ? state.cashOffers[0].price + state.cashOffers[0].coupon + state.cashOffers[0].promo : finalPrice,
            voucherPrice: state.voucherOffers ? state.voucherOffers[0].price + state.voucherOffers[0].coupon + state.voucherOffers[0].promo : finalPrice,
            condition: changeToEng(condition),
            capacity: device.capacity,
            lang: i18n.language,
            partner: partner,
            category: category,
            link: window.location.href + '/offer'
        }, { headers2 }).then(res => {
        })

        navigate(`${process.env.PUBLIC_URL}/${partner}/${brand}/${model}/${capacity}/${powerson}/${condition}/${contact}/offer`);
    }

    function validateEmail(email) {
        if (/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(email)) return true;
        return false;
    }

    function checkEnterKey(e) {
        if (e.key === 'Enter' && validateEmail(email)) {
            e.preventDefault();
            sendOffer()

        } else if (!validateEmail(email) && e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <div className="py-3">
                {!validateEmail(email) && `${t('email.valid1')}`}
            </div>
        </Tooltip>
    );

    if (submit) {
        window.step = 'offer';
    }
    else
        window.step = contact;

    window.brand = brand;
    window.model = model;
    window.capacity = capacity.toUpperCase();
    window.working = powerson;
    window.condition = condition;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const back = () => {
        navigate(`${process.env.PUBLIC_URL}/${props.partner}/${brand}/${model}/${capacity}/${powerson}/${condition}`);
    }
    let modelImage = replaceSpecialChars(model)


    if (!device) return (<>{window.location = process.env.PUBLIC_URL+"/"} </>);
    else return (
        <div>
            <Row className="justify-content-center mb-4">
                <Col xs="9" lg="10" className="text-center">
                    <h1 className="title d-lg-none">{t('email.title1')}{device.name}{t('email.title2')}</h1>
                    <h1 className="title d-none d-lg-block">{t('email.title1')}{device.name}{t('email.title2')}</h1>
                </Col>
            </Row>
            <Row className="justify-content-center align-items-center pb-5">
                <Col xs="12" sm="5" lg="4" xl="4" className="text-center mb-4 mb-sm-0">
                    {device.brand === "Apple" && <img src={`${CDN}/devices/` + brand + "/" + modelImage + ".jpg"} onError={(e) => { e.target.src = process.env.PUBLIC_URL + '/phone.png'; e.target.onError = null; }} style={{ maxWidth: '180px' }} width="50%" alt="phone" />}
                    {device.brand !== "Apple" && <img src={`${CDN}/devices/` + brand + "/" + modelImage + ".png"} onError={(e) => { e.target.src = process.env.PUBLIC_URL + '/phone.png'; e.target.onError = null; }} style={{ maxWidth: '180px' }} width="50%" alt="phone" />}
                </Col>
                <Col xs="10" sm="6" lg="5" xl="5" className="text-left">
                    <div className="ml-1" style={{ fontSize: '16px' }}>{t('email.description1')}<b className={`${partner}Text`}>email</b>{t('email.description2')}</div>
                    <Form className="mt-4">
                        <Form.Group>
                            <InputGroup className="myInput">
                                <InputGroup.Prepend>
                                    <InputGroup.Text></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control maxLength={50} type="email" className="email" placeholder="Email..." onChange={e => setEmail(e.target.value)} onKeyPress={(e) => { checkEnterKey(e) }} />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                    <div className="text-left ml-1 pb-5 pb-sm-1" style={{ fontSize: '9px' }}>{t('email.terms1')}<span className="terms" onClick={handleShow}>{t('email.terms2')}</span></div>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <h3 className="text-right mt-3 mr-3 mb-0" onClick={handleClose} style={{ cursor: "pointer", overflow: 'st' }} ><FontAwesomeIcon icon={faTimes} /></h3>
                <Modal.Body style={{ height: '75vh', overflow: 'scroll' }} className="pt-0">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Terms />
                    </Suspense>
                </Modal.Body>
            </Modal>
            <div className="footer row justify-content-end align-items-center px-3 px-md-5">
                <div className="d-flex">
                    <div className="back backEmail mr-3 mr-md-5" onClick={back}>
                        {t('footer.back')}
                    </div>
                    {(validateEmail(email)) &&
                        <div disabled={submit} type="submit" className={`${partner}Next px-3 next`} onClick={(e) => { e.preventDefault(); sendOffer() }}>
                            {t('email.getOffer')}
                        </div>}
                    {!(validateEmail(email)) &&
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 2000 }}
                            overlay={renderTooltip} >
                            <div type="button" className={`${partner}Next next px-3 disabled foot`}>{t('email.getOffer')}</div>
                        </OverlayTrigger>}
                </div>
            </div>
        </div>
    );
}


export default withTranslation()(Email);